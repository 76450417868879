// Ionic Storage for persisting data
import { Storage } from "@ionic/storage";

// Init persistent storage DB
// This will use the most appropriate storage implementation for the platform.
console.log(`🛳️ Initializing persistent storage`);
const persistDb = new Storage({
    name: '__wetixdb'
});

// Export the store
export { persistDb }