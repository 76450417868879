
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { pin, walk, warning, chevronBack, closeCircle, checkmarkCircle, checkmarkDone } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useAuthStore, useDeviceConfigStore } from '@/store';


export default defineComponent({
    components: { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton, IonGrid, IonCol, IonRow },
    setup() {
        const authStore = useAuthStore();
        const deviceConfig = useDeviceConfigStore();

        return {
            warning,
            walk,
            chevronBack,
            closeCircle,
            checkmarkCircle,
            checkmarkDone,
            pin,
            authStore,
            deviceConfig
        };
    }
});
