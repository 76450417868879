import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

/* Ionic Components */
import {
  IonicVue,
  IonContent,
  IonPage,
  IonChip,
  IonLabel,
  IonIcon,
  IonMenu,
  IonMenuToggle,
  IonModal,
  IonInput,
  IonItem,
  IonButton,
} from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Tailwind CSS */
import "./theme/tailwind.css";

// Set up peristent storage
import { persistDb } from '@/store/persistDb';


// Set up Pinia
import { createPinia } from "pinia";
import { useAuthStore, useDeviceConfigStore } from "@/store";
const pinia = createPinia();
const app = createApp(App).use(IonicVue).use(pinia);


// Globally import ionic components. 
app.component('ion-content', IonContent);
app.component('ion-page', IonPage);
app.component('ion-Icon', IonIcon);

// TODO: Import these components locally instead of globally for efficiency!
app.component('ion-chip', IonChip);
app.component('ion-label', IonLabel);
app.component('ion-menu', IonMenu);
app.component('ion-menu-toggle', IonMenuToggle);
app.component('ion-modal', IonModal);
app.component('ion-input', IonInput);
app.component('ion-item', IonItem);
app.component('ion-button', IonButton);

// get the auth store
const authStore = useAuthStore();
const deviceConfigStore = useDeviceConfigStore();



// initialize auth listener to see if we
// have a user at startup
authStore.initializeAuthListener().then(() => {
  // Create persistent storage
  persistDb.create()
  // Load deviceConfig state from persistent storage into pinia
  deviceConfigStore.loadPersist();

  // Subscribe to changes in deviceConfig state and update persistent storage
  deviceConfigStore.$subscribe((mutation, state) => {
    console.log(`deviceConfig changes:`, { mutation, state })

    // persist the whole state to the local storage whenever it changes
    deviceConfigStore.updatePersist()
  })

  // Mount the app
  app.use(router).mount("#app");
}).catch(err => console.error(err));
