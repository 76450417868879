// Imports
import { defineStore } from "pinia";
import { fbDocListener } from "./firebase";

// Persistent storage
import { persistDb } from '@/store/persistDb';

// Toast Deps
import { toastController } from '@ionic/vue';
import { alert } from 'ionicons/icons';


interface DeviceConfigState {
  isInitialized: boolean;
  isConfigured: boolean;
  isSyncing: boolean;
  tenantId: string;
  deviceId: string;
  boClientConfig: BoxofficeDeviceClientConfig;
  permissions: DevicePermissions[];
  error: string | null;
}

interface BoxofficeDeviceClientConfig {
  isApproved: boolean;
  label: string;
  cloudEndpoint: string;
  featureFlags: string[];
  supportContact: string;
}

interface DevicePermissions {
  tenantId: string; // Indicates the tenant ID for which these permissions apply
  allowedEventIds: string[]; // Indicates the event IDs which this client device has permission to access.
  allEventsAllowed: boolean; // Indicates this client is allowed access to all events.
}

export const useDeviceConfigStore = defineStore("deviceConfigStore", {
  state: (): DeviceConfigState => ({
    isInitialized: false,
    isConfigured: false,
    isSyncing: false,
    tenantId: '', // Tenant ID should be set using provisioning QR code scan.
    deviceId: '',  // Device ID should be set using provisioning QR code scan.
    boClientConfig: {
      isApproved: false,
      label: '',
      cloudEndpoint: '',
      featureFlags: [],
      supportContact: '',
    },
    permissions: [],
    error: null,
  }),
  getters: {
    activeDeviceId: (state) => state.deviceId || '',
    deviceConfigError: (state) => state.error,
  },
  actions: {
    /**
     * listen for changes on device config doc and update
     * store.
     */
    initializeDeviceConfigListener() {
      if (!this.deviceId) return console.error(`No device ID is set. Cannot sync device config!`)
      return new Promise((resolve) => {
        fbDocListener(`deviceConfigs/${this.deviceId}`, async (data: any) => {
          console.log(data)
          if (!data._exists) {
            this.showToast({
              header: 'Connection Failed',
              message: `Device ID ${this.deviceId} could not be found on the server.`,
              icon: alert,
              color: 'danger',
              position: 'top',
              duration: 3000,
            })
            // Reset state if there's an error
            this.$reset()
            this.error = 'Device ID not found'
            return console.error(`Config doc for device with ID ${this.deviceId} not found!`)
          }
          this.boClientConfig = data.boClientConfig ? data.boClientConfig : null;
          this.isSyncing = data.deviceId ? true : false;
          this.isConfigured = data.isConfigured
          this.isInitialized = true;
          this.tenantId = data.tenantId
          this.updatePersist()
          this.error = null;
          resolve(true);
        });
      });
    },
    updateDeviceId(deviceId: string) {
      this.deviceId = deviceId;
      this.initializeDeviceConfigListener()
    },
    async updatePersist() {
      console.log(`🚢  Updating device config persistent storage`)
      persistDb.set('deviceConfig', JSON.stringify(this.$state))
    },
    async loadPersist() {
      console.log(`🗃️ Loading deviceConfig state from persistent storage.`)
      this.$patch(JSON.parse(await persistDb.get('deviceConfig')))
    },
    async showToast(options: any) {
      const toast = await toastController
        .create(options)
      return toast.present();
    },
    async factoryReset() {
      console.warn(`Resetting state`)
      this.$reset()
      console.warn(`Clearing persistDb`)
      await persistDb.clear()
      console.log(`Factory reset complete.`)
    }
  }
});

