
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { ellipse, square, triangle, ticketOutline, listCircleOutline, scanOutline, settingsOutline, ellipsisVerticalOutline, homeOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useDeviceConfigStore } from '@/store';
import DeviceLinkingIntro from '@/components/DeviceLinkingIntro.vue';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet, DeviceLinkingIntro },
  setup() {
    const deviceConfig = useDeviceConfigStore();

    return {
      deviceConfig,
      homeOutline,
      ellipse,
      square,
      triangle,
      ticketOutline,
      listCircleOutline,
      scanOutline,
      settingsOutline,
      ellipsisVerticalOutline
    }
  }
});
