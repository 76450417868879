import { initializeApp } from "firebase/app";

import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  getDocs,
  collection,
  onSnapshot,
  updateDoc,
  initializeFirestore,
  enableIndexedDbPersistence,
  CACHE_SIZE_UNLIMITED
} from "firebase/firestore";

import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";


// Set firebase config in env vars (in build environment!)
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};


// Initialize firebase SDK
const app = initializeApp(firebaseConfig);
// Init firestore with cache size unlimited.
const firestoreDb = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED // Initialize firestore with unlimited cache
});

// Enable offline persistence.
console.log(`Enabling offline persistence..`)
enableIndexedDbPersistence(firestoreDb)
  .catch((err) => {
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
      console.error('Multiple tabs open, unable to enable offline persistence.')
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
      console.error('This device does not support offline persistence.')
    }
  });


const db = getFirestore(app);
const auth = getAuth(app);


/**
 * Creates a firestore user account.
 * @param email
 * @param password
 * @returns
 */
export const fbCreateAccount = async (
  email: string,
  password: string,
  first: string,
  last: string
) => {
  const response = await createUserWithEmailAndPassword(auth, email, password);
  console.log(response);
  if (response) {
    await fbSetUserProfile({ first, last, age: 67 });
    const profile = await fbGetUserProfile();
    return {
      user: response.user,
      profile,
    };
  } else {
    return {
      user: null,
      profile: null,
    };
  }
};

/**
 * Signs in a user
 * @param email
 * @param password
 * @returns
 */
export const fbSignIn = async (email: string, password: string) => {
  const response = await signInWithEmailAndPassword(auth, email, password);
  console.log(response);
  return response;
};

/**
 * Signs out a user
 * @returns
 */
export const fbSignOut = async () => {
  await signOut(auth);
  return true;
};

/**
 * Listens for onAuthStateChanged events and returns user object to the callback when it changes.
 * @param callback
 */
export const fbAuthStateListener = (callback: any) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      callback(user);
    } else {
      // User is signed out
      callback(null);
    }
  });
};

/**
 * Sets user profile in firestore
 * @param callback
 */
export const fbSetUserProfile = async ({
  first,
  last,
  age,
}: {
  first: string;
  last: string;
  age: number;
}) => {
  const user = auth.currentUser;
  console.log(user);

  const ref = doc(db, "scannerUserProfiles", user?.uid as string);
  await setDoc(
    ref,
    {
      first,
      last,
      age,
      uid: user?.uid,
    },
    { merge: true }
  );
  return true;
};

/**
 * Gets the user's profile from firestore
 * @returns
 */
export const fbGetUserProfile = async () => {
  const user = auth.currentUser;
  console.log(user);

  const ref = doc(db, "scannerUserProfiles", user?.uid as string);
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return {
      ...docSnap.data(),
      uid: user?.uid,
    };
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!", user?.uid);
    return null;
  }
};

/**
 * Querys for a collection of documents.
 * @collectionName {*} Name of the collection to query
 */
export const queryObjectCollection = async ({
  collectionName,
}: {
  collectionName: string;
}) => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  const results: any[] = [];

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    results.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return results;
};

/**
 * Listens for updates on a collection and passes new data to the callback.
 * @param collectionName 
 * @param callback 
 * @returns unsubscribe function - Call this function to unsubscribe from the collection.
 */
export const fbCollectionListener = (collectionName: string, callback: any) => {
  return onSnapshot(
    collection(db, collectionName),
    (snapshot) => {
      // ...
      console.log("Listening To Collection: " + collectionName, snapshot);
      const results: any[] = [];
      snapshot.forEach((doc) => {
        results.push({
          _id: doc.id,
          _hasPendingWrites: doc.metadata.hasPendingWrites, // Add _hasPendingWrites to all docs returned to indicate if a sync with server-side is pending.
          ...doc.data(),
        });
      });
      callback(results);
    },
    (error) => {
      // ...
      console.log("Error Listening To Collection: " + collectionName, error);
    }
  );
};

/**
 * Listens for updates on a doc and passes new data to the callback.
 * @param collectionName 
 * @param callback 
 * @returns unsubscribe function - Call this function to unsubscribe from the collection.
 */
export const fbDocListener = (docPath: string, callback: any) => {
  return onSnapshot(
    doc(db, docPath),
    (doc) => {
      // ...
      console.log("Listening To doc: " + docPath, doc);
      const docData = {
        _exists: doc.exists(),
        _id: doc.id,
        _hasPendingWrites: doc.metadata.hasPendingWrites, // Add _hasPendingWrites to all docs returned to indicate if a sync with server-side is pending.
        ...doc.data(),
      }
      callback(docData);
    },
    (error) => {
      // ...
      console.log("Error Listening To doc: " + docPath, error);
    }
  );
};

/**
 * Listens for updates on a query and passes new data to the callback.
 * @param q - firestore query to watch
 * @param callback 
 * @returns unsubscribe function - Call this function to unsubscribe from the collection.
 */
export const fbQueryListener = (q: any, callback: any) => {
  return onSnapshot(
    q, (querySnapshot) => {
      // ...
      console.log("Listening to query.. ");
      const results: any[] = [];
      querySnapshot.forEach((doc) => {
        results.push({
          _id: doc.id,
          _hasPendingWrites: doc.metadata.hasPendingWrites, // Add _hasPendingWrites to all docs returned to indicate if a sync with server-side is pending.
          ...doc.data(),
        });
      });
      callback(results);
    },
    (error) => {
      // ...
      console.log("Error listening to query ", error);
    }
  );
};

/**
 * Updates a document in firestore
 * @docPath {*} Document path to update.
 * @data {*} Data to update.
 */
export const fbUpdateDoc = async ({
  docPath, data
}: {
  docPath: string;
  data: any;
}) => {
  const docRef = doc(db, docPath);
  return updateDoc(docRef, data);
};

export { app, db, auth };
