import { useAuthStore } from "@/store";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import TabsPage from "../views/TabsPage.vue";

/**
 * 
 * @param to 
 * @param from 
 * @param next 
 */
const authCheck = (to: any, from: any, next: any) => {
  const store = useAuthStore();
  console.log("authCheck", store.isLoggedIn);
  if (store.isLoggedIn) {
    if (to.name === "login") {
      next({ name: "tabHome" });
    } else {
      next();
    }
  } else {
    if (to.name === "login") {
      next();
    } else {
      next({ name: "login" });
    }
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/LoginPage.vue"),
    beforeEnter: authCheck,
  },
  {
    name: "create-account",
    path: "/create-account",
    component: () => import("@/views/CreateAccountPage.vue"),
  },
  {
    name: "device-connection",
    path: "/device-connection",
    component: () => import("@/views/DeviceConnectionPage.vue"),
  },
  {
    path: "/",
    redirect: "/tabs/home",
  },
  {
    path: "/tabs/",
    component: TabsPage,
    children: [
      {
        path: "",
        redirect: "/tabs/home",
      },
      {
        path: "home",
        name: "tabHome",
        component: () => import("@/views/TabHome.vue"),
        beforeEnter: authCheck,
      },
      {
        path: "settings",
        component: () => import("@/views/TabSettings.vue"),
        beforeEnter: authCheck,
      },
      {
        path: 'scan',
        component: () => import('@/views/TabScan.vue'),
        beforeEnter: authCheck,
      },
      {
        path: 'tickets',
        component: () => import('@/views/TabTickets.vue'),
        beforeEnter: authCheck,
      },
      {
        path: 'history',
        component: () => import('@/views/TabHistory.vue'),
        beforeEnter: authCheck,
      }
    ],
  },
  {
    name: "dev-tollbars",
    path: "/dev/toolbars",
    component: () => import("@/views/dev/DevToolbarsPage.vue"),
  },
  {
    path: '/more',
    component: () => import('@/views/MorePage.vue'),
    beforeEnter: authCheck,
  }
  // { path: "*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
