import { defineStore } from "pinia";
import { db, fbCollectionListener, queryObjectCollection, fbUpdateDoc } from "./firebase";
import {
  serverTimestamp,
  increment,
  collection,
  query,
  where,
  onSnapshot,
  updateDoc,
  doc,
  clearIndexedDbPersistence,
  terminate
} from "firebase/firestore";
import _ from 'lodash';

// Typings for store
interface State {
  activeTenant: string;
  tickets: any[];
  error: null;
  currentTicketPage: number;
  ticketsPerPage: number;
  currentTicketSliceStartIndex: number;
  loading: boolean;
  searchQuery: string;
  searchResults: any[];
  searchableTickets: any[];
  enableSearch: boolean;
  unsubscribeTicketsListener: any;
  ticketsAddsCount: number; // Count of tickets added from server since init.
  ticketsModifiedCount: number; // Count of tickets modified server-side since init.
  ticketsRemovedCount: number; // Count of tickets removed server-side since init.
  ticketsChangesCount: number; // Count of tickets changes (total adds, mods, removes) received from server-side since init.
  eventIdsToSync: string[];
  offlinePersistenceEnabled: boolean;
  offlinePersistenceSupported: boolean;
  offlinePersistanceDesired: boolean;
  offlinePersistenceErrorMessage: string;
}

// Define Pinia store
export const useTicketStore = defineStore("ticketstore", {
  // convert to a function
  state: (): State => ({
    loading: false,
    activeTenant: 'tunes',
    tickets: [],
    error: null,
    currentTicketPage: 0,
    ticketsPerPage: 15,
    currentTicketSliceStartIndex: 0,
    searchQuery: '',
    searchResults: [],
    searchableTickets: [],
    enableSearch: false,
    unsubscribeTicketsListener: null, // Unsubscribe from ticket listener fn gets put here.
    ticketsAddsCount: 0,
    ticketsModifiedCount: 0,
    ticketsRemovedCount: 0,
    ticketsChangesCount: 0,
    eventIdsToSync: [],
    offlinePersistenceEnabled: false,
    offlinePersistenceSupported: true,
    offlinePersistanceDesired: true,
    offlinePersistenceErrorMessage: ''
  }),
  getters: {
    allTickets: (state) => state.tickets,
    ticketError: (state) => state.error,
    totalTickets: (state) => {
      if (state.tickets && state.tickets.length) {
        return state.tickets.length;
      } else {
        return 0;
      }
    },
    ticketsPage: (state) => {
      if (state.searchResults.length) return state.searchResults
      const startIndex = state.currentTicketSliceStartIndex
      const endIndex = startIndex + state.ticketsPerPage
      if (!state.tickets) { return [] }

      return state.tickets.slice(startIndex, endIndex)
    },
    // searchableTickets: (state) => {
    //   if (!state.searchQuery) return []
    //   return state.tickets
    // },
    // searchResult: (state) => {
    //   if(!state.searchQuery) return []
    //   const { results, noResults } = useVueFuse(state.)
    //   if(noResults) return []
    //   return results
    // }
  },
  actions: {
    /**
     * Set up - fetches tickets and starts listener. Should be called on mounted.
     */
    async initData() {
      try {
        console.log(`Starting ticket data handling..`);
        this.loading = true;
        // Init firestore listener
        await this.initializeTicketListener(this.eventIdsToSync)
        this.error = null;
        this.loading = false;
        return true
      } catch (e: any) {
        console.error(e)
        this.error = e;
        return false
      }
    },
    /**
     * listen for changes on collection and update
     * store.
     * 
     * @param collectionName 
     * @returns 
     */
    initializeCollectionListener(collectionName: string) {
      return new Promise((resolve) => {
        fbCollectionListener(collectionName, async (data: any) => {
          this.tickets = data ? data : null;
          this.error = null;
          resolve(true);
        });
      });
    },
    /**
    * ticket query specific firestore listener,
    * efficiently hydrates and updates ticket documents in 
    * 
    * @param array - array of event ID's for which to watch, or pass null for all events. 
    * @returns 
    */
    initializeTicketListener(eventIds: string[]) {
      // firestore 'in' queries support max 10 values
      if (eventIds?.length > 10) {
        throw new Error(`You can sync a maximum of 10 events.`)
      }

      // Ref to tenants ticket collection 
      const ticketsCollectionRef = collection(db, `tenants/${this.activeTenant}/tickets`)
      // Construct query
      let q
      if (!eventIds || eventIds.length === 0) {
        // If no eventIds passed in, watch ALL tickets.
        console.log(`Querying for tickets for all events`)
        q = query(ticketsCollectionRef);
      }
      if (eventIds && eventIds.length === 1) {
        console.log(`Querying for tickets for ${eventIds[0]}`)
        q = query(ticketsCollectionRef, where('eventId', '==', eventIds[0]));
      }
      if (eventIds && eventIds.length > 1) {
        console.log(`Querying for tickets for ${eventIds.length} events`)
        q = query(ticketsCollectionRef, where('eventId', 'in', eventIds));
      }

      console.log(`Starting query:`, q)

      return new Promise((resolve) => {
        this.ticketListener(q, async (updates: any) => {
          console.log('Updates from listener:', updates)
          this.error = null;
          resolve(true);
        });
      });
    },
    /**
     * Listens for updates on a query and passes new data to the callback.
     * @param q - firestore query to watch
     * @param callback 
     * @returns unsubscribe function - Call this function to unsubscribe from the collection.
     */
    ticketListener(q: any, callback: any) {
      this.unsubscribeTicketsListener = onSnapshot(
        q,
        // DocumentSnapshot handler..
        (querySnapshot) => {
          // ...
          console.log("Listening to query.. ");
          const updates: any[] = [];
          console.log(`Received ${querySnapshot.docChanges().length} updates from server.`)
          // Handle changes efficiently
          querySnapshot.docChanges().forEach((change) => {
            this.ticketsChangesCount++
            if (change.type === "added") {
              // Count how many added docs we receive.
              this.ticketsAddsCount++
              // Add new tickets to ticket store to populate UI.
              // Note: The first query snapshot will contain added events for all docs,
              // even if they're sourced from local cache.
              console.log("Ticket Sync - Received ticket added event");
              this.tickets.push({
                _id: change.doc.id, // Ensure all docs have ID
                _hasPendingWrites: change.doc.metadata.hasPendingWrites, // Add _hasPendingWrites to all docs returned to indicate if a sync with server-side is pending.
                ...change.doc.data(), // Spread in doc data.
              });
            }
            if (change.type === "modified") {
              this.ticketsModifiedCount++
              // When a ticket is modified, we will find the original and update it,
              // rather than dropping and re-populating the entire collection in memory.
              console.log("Ticket Sync - Received ticket modified event");
              console.log(change)
              // Find index of the modified data.
              const modIndex = _.findIndex(this.tickets, ['_id', change.doc.id]);
              // Replace modified data.
              this.tickets[modIndex] = {
                _id: change.doc.id, // Ensure all docs have ID
                _hasPendingWrites: change.doc.metadata.hasPendingWrites, // Add _hasPendingWrites to all docs returned to indicate if a sync with server-side is pending.
                ...change.doc.data(), // Spread in doc data.
              }
            }
            if (change.type === "removed") {
              this.ticketsRemovedCount++
              console.log("Ticket Sync - Received ticket removed event");
              // Remove removed data from ticket store.
              const removedIndex = _.findIndex(this.tickets, ['_id', change.doc.id]);
              _.pullAt(this.tickets, removedIndex);
            }

          });
          callback(updates);
        },
        (error) => {
          // ...
          console.log("Error listening to tickets query ", error);
        }
      );
    },
    /**
     * make intentional call to load tickets for the events we want
     * UNUSED
     * @param data
     */
    async loadTickets() {
      try {
        this.loading = true;
        const data = await queryObjectCollection({
          collectionName: `tenants/${this.activeTenant}/tickets`,
        });
        this.tickets = data ? data : [];
        this.error = null;
        this.loading = false;
        return this.tickets;
      } catch (e: any) {
        this.tickets = [];
        this.error = e;
        this.loading = false;
        return false;
      }
    },
    /**
     * mark a ticket as checked in
     */
    async checkInTicket(ticket) {
      try {
        console.log(`Checking in ticket with ID ${ticket.id}`);
        // Write change to firestore.
        const docRef = doc(db, `tenants/${this.activeTenant}/tickets/${ticket.id}`)
        const result = await updateDoc(docRef, {
          checkedIn: true,
          checkInMeta: {
            timestampClient: new Date().toISOString(),
            timestampServer: serverTimestamp(),
            checkinClientTenant: this.activeTenant,
            checkinClientUser: 'UNKNOWN', // TODO
            timesCheckedIn: increment(1),
            // timesCheckedOut: increment(1),
          }
        })

        console.log(`Check in ticket ${ticket.id} result: `, result)

        return true
      } catch (e: any) {
        console.error(e)
        this.error = e;
        return false;
      }
    },
    /**
     * mark a ticket as not checked in
     */
    async unCheckTicket(ticket) {
      try {
        console.log(`Un-checking in ticket with ID ${ticket.id}`);
        const result = await fbUpdateDoc({
          docPath: `tenants/${this.activeTenant}/tickets/${ticket.id}`,
          data: {
            checkedIn: false,
            checkInMeta: {
              timestampClient: new Date().toISOString(),
              timestampServer: serverTimestamp(),
              unCheckinClientTenant: this.activeTenant,
              unCheckinClientUser: 'UNKNOWN', // TODO
              // timesCheckedIn: increment(1),
              timesCheckedOut: increment(1),
            }
          }
        })

        console.log(`Un-Check in ticket ${ticket.id} result: `, result)

        return true
      } catch (e: any) {
        console.error(e)
        this.error = e;
        return false;
      }
    },
    /**
     * toggle check-in status for ticket
     */
    async toggleTicketCheckin(ticket) {
      try {
        console.log(`Toggling check-in status for ticket with ID ${ticket.id}`);

        if (ticket.checkedIn) {
          return this.unCheckTicket(ticket);
        } else {
          return this.checkInTicket(ticket);
        }
      } catch (e: any) {
        console.error(e)
        this.error = e;
        return false;
      }
    },
    /**
     * show more tickets on the page
     */
    async showMoreTickets(n) {
      try {
        this.ticketsPerPage = this.ticketsPerPage + (n || 10)
      } catch (e: any) {
        console.error(e)
        return false;
      }
    },
    /**
     * reset everything, terminating firestore, listeners and flush cache
     */
    async flushTicketData() {
      try {
        console.warn('Stopping ticket snapshot listener...');
        await this.unsubscribeTicketsListener()
        console.warn('Terminating firestore...');
        await terminate(db)
        console.warn('Resetting persistent state...')
        await clearIndexedDbPersistence(db)
        console.warn('Resetting ticket store...')
        this.$reset()
      } catch (e: any) {
        console.error(e)
        return false;
      }
    },


    // -----
  },
});
